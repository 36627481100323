import "whatwg-fetch";

// import axios from "axios";
import { captureException } from "@sentry/vue";

// Add a response interceptor
// interceptors.response.use((response) => response, (error) => {
//     captureException(error, {
//         extra: {
//             type: error.response.config.method,
//             url: error.response.config.url,
//             data: error.response.config.data,
//             status: error.response.status,
//             response: typeof error.response.data === "string" ? error.response.data.substr(0, 100) : "",
//             error: error || error.response.statusText,
//         },
//     });
//     return Promise.reject(error);
// });

const checkStatus = (response, method) => {
    if (response.ok) {
        return response;
    }
    const error = new Error(response.statusText);
    error.response = response;

    if (response.status === 401) {
        window.location = "/";
    } else {
        captureException(error, {
            extra: {
                method,
                url: response.url,
                status: response.status,
                error: response.statusText,
            },
        });
    }
    throw error;
};

const parseJSON = (response) => response.json();

function get(url, queryParams = {}) {
    const method = "GET";
    const options = {
        method,
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
        },
    };

    let requestUrl = url;
    if (queryParams && queryParams.params) {
        Object.keys(queryParams.params).forEach((key) => {
            if (!key || !key.length) {
                delete queryParams.params[key];
            }
        });
        const queryString = queryParams.params ? Object.keys(queryParams.params).map((key) => `${key}=${queryParams.params[key]}`).join("&") : "";
        if (requestUrl.indexOf("?") === -1) {
            requestUrl += `?${queryString}`;
        } else {
            requestUrl += `&${queryString}`;
        }
    }

    return fetch(requestUrl, options)
        .then((res) => checkStatus(res, method))
        .then(parseJSON);
}

function post(url, params = {}) {
    const method = "POST";
    const options = {
        method,
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(params),
    };
    return fetch(url, options)
        .then((res) => checkStatus(res, method))
        .then(parseJSON);
}

function postToWallet(data, success, failure) {
    post("/app/w/wallet", data)
        .then((response) => {
            if (success) {
                success(response);
            }
        }, (error) => {
            if (failure) {
                failure(error);
            }
        });
}

export default {
    getHomePageData(success, failure) {
        get("/app/w/home")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getDeliveryAddress(success, failure) {
        get("/app/w/delivery-addresses")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getCartBadge(success, failure) {
        get(`/app/w/cart?_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getCartDetails(params, success, failure) {
        get(`/app/w/cart?details=1&_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getUserState(success, failure) {
        get(`/app/w/auth/state?_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    loginStatus(data, success, failure) {
        post("/app/w/auth/status", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    migrate(data, success, failure) {
        post("/app/w/auth/migrate", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    verifyMobileAuth(data, success, failure) {
        post("/app/w/auth/verify-mobile", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    verifyForgotOtp(data, success, failure) {
        post("/app/w/auth/verify-forgot-otp", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    softRegister(data, success, failure) {
        post("/app/w/auth/soft", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    login(data, success, failure) {
        delete data.newsletter;
        delete data.name;
        post("/app/w/auth/login", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    fbLogin(data, success, failure) {
        post("/app/w/auth/fblogin", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    register(data, success, failure) {
        post("/app/w/auth", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    logout(success, failure) {
        get(`/app/w/auth/logout?_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getProfile(success, failure) {
        get(`/app/w/profile?_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    updateGender(data, success, failure) {
        post("/app/w/gender", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    updateProfile(data, success, failure) {
        post("/app/w/profile", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                failure(error);
            });
    },

    changePasswd(data, success, failure) {
        post("/app/w/change-password", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getOrders(params, success, failure) {
        get(`/app/w/orders?_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getOrderDetails(params, success, failure) {
        get(`/app/w/order-details?_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getOrderReviews(params, success, failure) {
        get(`/app/w/order-reviews?_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getWallet(success, failure) {
        get(`/app/w/wallet?_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getWalletDetails(success, failure) {
        get(`/app/w/wallet?details=1&_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getWalletAccount(params, success, failure) {
        get(`/app/w/wallet?_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    addUpdateAccount(data, success, failure) {
        data.submitAccount = true;
        postToWallet(data, success, failure);
    },

    initiateBankTransfer(data, success, failure) {
        data.submitTransfer = true;
        postToWallet(data, success, failure);
    },

    verifyTransferOtp(data, success, failure) {
        post("/app/w/verify-transfer-otp", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    addGiftCard(data, success, failure) {
        data.submitGC = true;
        postToWallet(data, success, failure);
    },

    getIfscDetails(ifsc_code, success, failure) {
        get(`/app/w/ifsc-details?ifsc_code=${ifsc_code}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getPincodeDetails(pincode, success, failure) {
        get(`/app/w/pincode-details?pincode=${pincode}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getAddresses(params, success, failure) {
        get(`/app/w/addresses?_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    addUpdateAddress(data, success, failure) {
        post("/app/w/addresses", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    deleteAddress(data, success, failure) {
        post("/app/w/addresses?delete=1", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    validateResetPasswordRequest(data, success, failure) {
        post("/app/w/auth/validate-reset-password-request", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    forgotPassword(data, success, failure) {
        post("/app/w/auth/forgot-password", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    resendOtp(data, success, failure) {
        post("/app/w/auth/resend-otp", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    resetPassword(data, success, failure) {
        post("/app/w/auth/reset-password", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getWishlistProducts(success, failure) {
        get(`/app/w/wishlist?details=1&_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getWishlistProductIds(success, failure) {
        get(`/app/w/wishlist?_=${Math.random()}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    addProductToWishlist(data, success, failure) {
        post("/app/w/wishlist", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    removeProductFromWishlist(data, success, failure) {
        post("/app/w/wishlist?delete=1", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    requestReturn(data, success, failure) {
        post("/app/w/request-order-return", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    cancelOrder(data, success, failure) {
        post("/app/w/cancel-order", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getProductDetails(params, success, failure) {
        get("/app/w/product-details", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getSimilarProducts(params, success, failure) {
        get("/app/w/similar", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },
    getRecommendedProducts(params, success, failure) {
        get("/app/w/recommended-for-you", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },
    getCustomerWhoViewedAlsoViewed(params, success, failure) {
        get("/app/w/similar-viewed-products", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },
    getFrequentlyBoughtTogether(params, success, failure) {
        get("/app/w/frequently-bought-together", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getSimilarColorProducts(params, success, failure) {
        get("/app/w/similar-color", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getAccessories(params, success, failure) {
        get("/app/w/accessories", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getDeliveryDate(params, success, failure) {
        get("/app/w/edd", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getSizeChart(params, success, failure) {
        get("/app/w/sizeChart", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    postToCart(data, success, failure) {
        post("/app/w/cart", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    searchSuggestions(searchQuery, success, failure) {
        get("/app/w/suggestions", { params: { q: searchQuery } })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getBrandProducts(params, start, pageSize, success, failure) {
        if (start === 1) {
            params.p = 1;
        } else {
            params.p = parseInt((start / pageSize) + 1);
        }

        get("/app/w/brand-product-list", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getCategoryProducts(params, pageNum, pageSize, success, failure) {
        // let url = "";
        if (pageNum === 1) {
            params.p = 1;
            // url = "/app/w/product-list";
        } else {
            params.p = pageNum;
            // url = "/app/w/product-list-only-products";
        }

        get("/app/w/product-list", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getSearchResults(params, pageNum, pageSize, success, failure) {
        params.p = pageNum;

        get("/app/w/search", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getPaymentOptions(params, success, failure) {
        get(`/app/w/payment-options?_=${Math.random()}`, { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    removeSavedCard(data, success, failure) {
        post("/app/w/payment-remove-card", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    selectPaymentOption(data, success, failure) {
        post("/app/w/select-payment-option", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getCategories(success, failure) {
        get("/app/w/categories")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },
    getMenu(success, failure) {
        get("/app/w/menu")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    /*
    placeFreeOrder(success, failure) {
            post("/app/w/check-free")
            .then((response) => {
                    if (success) {
                    success(response);
                }
            }, (error) => {
                    if (failure) {
                    failure(error);
                }
            });
    },
    */

    updateSubscriptionOnServer(subscription, success, failure) {
        post("/app/w/subscribe-push", { subscription })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    changeMobile(data, success, failure) {
        post("/app/w/change-mobile", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    verifyMobileThankyou(data, success, failure) {
        post("/app/w/verify-mobile-thankyou", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    verifyMobile(data, success, failure) {
        post("/app/w/verify-mobile", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getBestSellers(success, failure) {
        get("/app/w/bestsellers")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },
    getNewProducts(success, failure) {
        get("/app/w/new-products")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getCmsData(id_cms, success, failure) {
        get(`/app/w/cms?id_cms=${id_cms}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getProductReviews(params, success, failure) {
        get("/app/w/product-reviews", { params })
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getCustomerProductReview(id_product, success, failure) {
        get(`/app/w/customer-product-review?id_product=${id_product}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    submitProductRating(data, success, failure) {
        post("/app/w/submit-rating", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    submitProductReview(data, success, failure) {
        post("/app/w/submit-review", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    sendFBEvent(data, success, failure) {
        post("/app/w/fbss-event", data)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getReferralUrl(success, failure) {
        get("/app/w/customer-referral-url")
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },

    getConfigs(name, success, failure) {
        get(`/app/w/configs?name=${name}`)
            .then((response) => {
                if (success) {
                    success(response);
                }
            }, (error) => {
                if (failure) {
                    failure(error);
                }
            });
    },
};
